export const DASHBOARD: string = `Dashboard`;
export const SALES: string = `Sales`;
export const PRODUCTS: string = `Products`;
export const PRODUCT_CREATE: string = `New Product`;
export const PRODUCT_EDIT: string = `Edit Product`;
export const BUNDLES: string = `Bundles`;
export const BUNDLE_CREATE: string = `New Bundle`;
export const BUNDLE_EDIT: string = `Edit Bundle`;
export const COUPONS: string = `Coupons`;
export const COUPON_CREATE: string = `New Coupon`;
export const COUPON_EDIT: string = `Edit Coupon`;
export const CUSTOMERS: string = `Users`;
export const LOGIN: string = `Log in`;

export const NOT_FOUND: string = `Not found`;
export const LOADING: string = `Loading...`;
export const CONTINUE: string = `Continue`;
export const CLOSE: string = `Close`;
export const SKIP: string = `Skip`;
