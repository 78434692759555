import axios from 'axios';
import { stringify } from 'qs';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { SERVER_STATUS } from './constants';
import { isBrowser } from '@core/utils/env';
import { AnyObject, API } from '@interface/common';


const api = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: 5000,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
  paramsSerializer: {
    serialize: (params) => stringify(params, { arrayFormat: 'brackets' }),
  },
});

api.interceptors.request.use(
  (config) => ({ ...config, data: decamelizeKeys(config.data) }),
);

api.interceptors.response.use(
  (response) => ({ ...response, data: camelizeKeys(response.data) }),
  (error) => {
    let output;
    if (error.response?.data) {
      if (error.response.status === SERVER_STATUS.INTERNAL_SERVER_ERROR.code) {
        output = SERVER_STATUS.INTERNAL_SERVER_ERROR;
      } else {
        output = camelizeKeys(error.response.data);
      }
    } else {
      output = { code: error.code, message: error.message };
    }
    return Promise.reject(output);
  },
);

async function request(url: string, data: AnyObject) {
  if (!isBrowser) return;

  const response = await api.post(url, data);
  return response.data;
}


export const logIn: API.LogIn.Request = async (params) => {
  return request('/login', params);
};


export const authenticate: API.Authenticate.Request = async () => {
  return request('/authenticate', {});
};

export const logOut: API.LogOut.Request = async () => {
  return request('/logout', {});
};

export const getUEVersions: API.GetUEVersions.Request = async () => {
  return request('/ue-versions/list', {});
};

export const createUEVersion: API.CreateUEVersion.Request = async (name) => {
  return request('/ue-versions/create', { name });
};

export const getCategories: API.GetCategories.Request = async () => {
  return request('/categories/list', {});
};

export const getProducts: API.GetProducts.Request = async () => {
  return request('/products/list', {});
};

export const getProduct: API.GetProduct.Request = async (slug) => {
  return request('/products/get', { slug });
};

export const createProduct: API.CreateProduct.Request = async (product) => {
  return request('/products/create', { product });
};

export const updateProduct: API.UpdateProduct.Request = async (product) => {
  return request('/products/update', { product });
};

export const createRelease: API.CreateRelease.Request = async (release) => {
  return request('/releases/create', { release });
};

export const updateRelease: API.UpdateRelease.Request = async (release) => {
  return request('/releases/update', { release });
};

export const getBundles: API.GetBundles.Request = async () => {
  return request('/bundles/list', {});
};

export const getBundle: API.GetBundle.Request = async (slug) => {
  return request('/bundles/get', { slug });
};

export const createBundle: API.CreateBundle.Request = async (bundle) => {
  return request('/bundles/create', { bundle });
};

export const updateBundle: API.UpdateBundle.Request = async (bundle) => {
  return request('/bundles/update', { bundle });
};

export const getCoupons: API.GetCoupons.Request = async () => {
  return request('/coupons/list', {});
};

export const getCoupon: API.GetCoupon.Request = async (code) => {
  return request('/coupons/get', { code });
};

export const createCoupon: API.CreateCoupon.Request = async (coupon) => {
  return request('/coupons/create', { coupon });
};

export const updateCoupon: API.UpdateCoupon.Request = async (coupon) => {
  return request('/coupons/update', { coupon });
};

export const getCustomers: API.GetCustomers.Request = async () => {
  return request('/customers/list', {});
};
