// noinspection JSIgnoredPromiseFromCall

import * as React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useRecoilValueLoadable } from 'recoil';
import { isUndefined } from 'lodash';
import { isAuthenticatedSelector } from '@core/atoms';
import { ACCESS } from '@core/constants';
import { AccessType } from '@interface/common';


const PRODUCTS_BASE: string = '/products';
const BUNDLES_BASE: string = '/bundles';
const COUPONS_BASE: string = '/coupons';

export const pages = {
  LOGIN: '/login',
  DASHBOARD: '/',
  SALES: '/sales',
  PRODUCTS: PRODUCTS_BASE,
  PRODUCT_CREATE: PRODUCTS_BASE + '/new',
  PRODUCT_EDIT: PRODUCTS_BASE + '/edit',
  BUNDLES: BUNDLES_BASE,
  BUNDLE_CREATE: BUNDLES_BASE + '/new',
  BUNDLE_EDIT: BUNDLES_BASE + '/edit',
  COUPONS: COUPONS_BASE,
  COUPON_CREATE: COUPONS_BASE + '/new',
  COUPON_EDIT: COUPONS_BASE + '/edit',
  CUSTOMERS: '/users',
};

export const routePath = (path: string, ...args: any): string => {
  if (args.length > 0) {
    return path + '/' + args.join('/');
  } else {
    return path;
  }
};

const cleanPath = (path: string): string => {
  return path.replace('/', '');
}

const authRoutes = (): string[] => {
  return [
    pages.LOGIN,
  ];
};

export function useRoutes() {
  return React.useMemo(() => {
    return {
      ...pages,
      routes: {
        auth: authRoutes(),
      },
      route: routePath,
      clean: cleanPath,
    };
  }, []);
}

export function useAccessOnly(access: AccessType, path?: string, condition: boolean = true) {
  const location = useLocation();
  const isAuthenticated = useRecoilValueLoadable(isAuthenticatedSelector);
  const { route, LOGIN, DASHBOARD } = useRoutes();

  function redirectToLogin() {
    if (!condition) return;

    if (isUndefined(path)) {
      navigate(route(LOGIN), { replace: true, state: { source: location!.pathname } });
    } else {
      navigate(path, { replace: true });
    }
  }

  function redirectToDashboard() {
    if (!condition) return;

    navigate(isUndefined(path) ? route(DASHBOARD) : path, { replace: true });
  }

  React.useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    switch (access) {
      case ACCESS.AUTHENTICATED:
        timer = setTimeout(() => {
          if (
            isAuthenticated.state === 'hasValue' && !isAuthenticated.contents ||
            isAuthenticated.state === 'hasError' && isAuthenticated.contents.code === 401
          ) {
            redirectToLogin();
          }
        }, 100);

        break;

      case ACCESS.UNAUTHENTICATED:
        if (isAuthenticated.state === 'hasValue' && isAuthenticated.contents) {
          redirectToDashboard();
        }
        break;
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [condition, path, isAuthenticated.state, location.pathname]);
}
