import { atom, atomFamily, selector, selectorFamily, AtomEffect, DefaultValue } from 'recoil';
import { sortBy } from 'lodash';
import { isBrowser } from '@core/utils/env';
import * as api from '@core/api';
import { CATEGORY_FILTER, COUPON_STATUS, CUSTOMER_FILTER } from '@core/constants';
import { API } from '@interface/common';


function prefix(key: string) {
  return `rebelthorp:${key}`;
}

const localStorageEffect = (key: string): AtomEffect<any> => ({ setSelf, onSet }) => {
  if (!isBrowser) return;
  const name = prefix(key);
  const savedValue = localStorage.getItem(name);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }
  onSet((newValue: any, _oldValue: any, isReset: boolean) => {
    if (isReset) {
      localStorage.removeItem(name);
    } else {
      localStorage.setItem(name, JSON.stringify(newValue));
    }
  });
};

export const hasAccountAtom = atom({
  key: '$hasAccountAtom',
  default: false,
  effects_UNSTABLE: [
    localStorageEffect('admin'),
  ],
});

export const accountQuery = selector({
  key: '$accountQuery',
  get: async ({ get }) => {
    const hasAccount = get(hasAccountAtom);
    if (hasAccount) {
      try {
        return await api.authenticate();
      } catch (error) {
        localStorage.removeItem(prefix('admin'));
        return error;
      }
    }
    return null;
  },
});

export const accountAtom = atom({
  key: '$accountAtom',
  default: selector({
    key: '$accountAtom/default',
    get: ({ get }) => {
      return get(accountQuery);
    },
  }),
});

export const accountSelector = selector<any>({
  key: '$accountSelector',
  get: ({ get }) => get(accountAtom),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      // Order matters
      reset(accountAtom);
      reset(hasAccountAtom);
    } else {
      set(hasAccountAtom, true);
      set(accountAtom, newValue);
    }
  },
});

export const isAuthenticatingAtom = atom<boolean>({
  key: '$isAuthenticatingAtom',
  default: false,
});

export const isAuthenticatedSelector = selector({
  key: '$isAuthenticatedSelector',
  get: ({ get }) => {
    const user = get(accountSelector);
    return !!user?.email;
  },
});

export const ueVersionsQuery = selector({
  key: '$ueVersionsQuery',
  get: async () => {
    const response = await api.getUEVersions();
    return response.ueVersions;
  },
});

export const categoriesQuery = selector({
  key: '$categoriesQuery',
  get: async () => {
    const response = await api.getCategories();
    return response.categories;
  },
});

export const productsQuery = selector({
  key: '$productsQuery',
  get: async () => {
    const response = await api.getProducts();
    return response.products;
  },
});

export const productsFilterState = atom({
  key: '$productsFilterState',
  default: CATEGORY_FILTER.ALL,
  effects_UNSTABLE: [
    localStorageEffect('showProducts'),
  ],
});

export const productsAutocompleteState = atom({
  key: '$productsAutocompleteState',
  default: selector({
    key: '$productsAutocompleteState/default',
    get: ({ get }) => {
      const products = get(productsQuery);
      return sortBy(products, ['title']);
    },
  }),
});

export const productQuery = selectorFamily<API.GetProduct.Response, string>({
  key: '$productQuery',
  get: (slug: string) => async () => {
    return await api.getProduct(slug);
  },
});

export const productState = atomFamily({
  key: '$productState',
  default: selectorFamily({
    key: '$productState/default',
    get: (slug: string) => ({ get }) => {
      return get(productQuery(slug));
    },
  }),
});

export const bundlesQuery = selector({
  key: '$bundlesQuery',
  get: async () => {
    const response = await api.getBundles();
    return response.bundles;
  },
});

export const bundleQuery = selectorFamily<API.GetBundle.Response, string>({
  key: '$bundleQuery',
  get: (slug: string) => async () => {
    return await api.getBundle(slug);
  },
});

export const bundleState = atomFamily({
  key: '$bundleState',
  default: selectorFamily({
    key: '$bundleState/default',
    get: (slug: string) => ({ get }) => {
      const { bundle, products } = get(bundleQuery(slug));
      return {
        bundle,
        products: sortBy(products, ['title']),
      };
    },
  }),
});

export const couponsQuery = selector({
  key: '$couponsQuery',
  get: async () => {
    const response = await api.getCoupons();
    return response.coupons;
  },
});

export const couponQuery = selectorFamily<API.GetCoupon.Response, string>({
  key: '$couponQuery',
  get: (code: string) => async () => {
    return await api.getCoupon(code);
  },
});

export const couponState = atomFamily({
  key: '$couponState',
  default: selectorFamily({
    key: '$couponState/default',
    get: (code: string) => ({ get }) => {
      const { coupon, products } = get(couponQuery(code));
      return {
        coupon,
        products: sortBy(products, ['title']),
      };
    },
  }),
});

export const couponsFilterState = atom({
  key: '$couponFilterState',
  default: COUPON_STATUS.ACTIVE,
  effects_UNSTABLE: [
    localStorageEffect('showCoupons'),
  ],
});

export const customersQuery = selector({
  key: '$customersQuery',
  get: async () => {
    const response = await api.getCustomers();
    return response.customers;
  },
});

export const customersFilterState = atom({
  key: '$customersFilterState',
  default: CUSTOMER_FILTER.ALL,
  effects_UNSTABLE: [
    localStorageEffect('showCustomers'),
  ],
});
