import * as React from 'react';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { useLocation } from '@reach/router';
import { clsx } from 'clsx';
import { useRoutes } from '@hooks/routes';
import MessageIcon from '@components/MessageIcon';
import ToastCloseButton from './parts/ToastCloseButton';
import { PageContext } from '@interface/gatsby';
import { MessageType } from '@interface/common';
import '../../styles.scss';


type MainLayoutProps = {
  children: React.ReactNode,
  pageContext: PageContext,
}

function MainLayout({ children }: MainLayoutProps) {
  const location = useLocation();
  const { route, LOGIN } = useRoutes();

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {});
    }
    return child;
  });

  return (
    <>
      {childrenWithProps}
      <ToastContainer
        className={clsx({ 'reset': location.pathname === route(LOGIN) })}
        limit={3}
        autoClose={4000}
        icon={({ type }: { type: MessageType }) => <MessageIcon type={type}/>}
        closeButton={<ToastCloseButton/>}
        closeOnClick
        newestOnTop
      />
    </>
  );
}


type AppProps = {
  children: React.ReactNode;
  pageContext: PageContext;
}

export default function App({ children, pageContext }: AppProps) {
  return (
    <RecoilRoot>
      <HelmetProvider>
        <MainLayout pageContext={pageContext}>
          {children}
        </MainLayout>
      </HelmetProvider>
    </RecoilRoot>
  );
}
