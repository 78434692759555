import * as ACCESS from './access';
import * as LABEL from './label';
import * as STORE from './store';
import * as CATEGORY_FILTER from './category_filter';
import * as COUPON_STATUS from './coupon_status';
import * as CUSTOMER_FILTER from './customer_filter';
import * as FILE_TARGET from './file_target';
import * as MESSAGE from './message';
import * as SERVER_STATUS from './server_status';

const DEFAULT_LOCALE = 'en-US';
const AUTH_DELAY = 300;

export {
  ACCESS,
  AUTH_DELAY,
  DEFAULT_LOCALE,
  LABEL,
  STORE,
  CATEGORY_FILTER,
  COUPON_STATUS,
  CUSTOMER_FILTER,
  FILE_TARGET,
  MESSAGE,
  SERVER_STATUS,
};
